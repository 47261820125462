import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../slices/thunks";
import { getOrganizationData, getOrganizationDevice } from "../../slices/data/thunk";
import DeviceItem from "./DeviceItem";
import DataConnector from "../../common/DataConnector";
import { updateOrganizationDataSuccess } from "../../slices/data/reducer";
import ChartSection from "./ChartSection";

const SPARKLINE_COLOR = ['#72c2ff', '#9ce89d', '#fda582'];

const getSparlineColor = (count) => {
  let countNum = count % 3;
  return SPARKLINE_COLOR[countNum];
}

export const getZoneHeader = (count, name, t) => {
  let countNum = count + 1;
  let colorTone = countNum%2===0?'l-green':countNum%3===0?'l-amber':'l-blue';
  return (
    <div key={count} className={"card dash-zone-header " + colorTone}>
      <div className="dash-zone-header-text">
        <h3>{t('Common.Zone') + t('Common.Space') + name}</h3>
      </div>
  </div>
  );
}

const onConnected = (dispatch) => {
  console.log("onConnected dispatch");

};
const onDisconnected = (dispatch) => {
  console.log("onDisconnected dispatch");
};
const onDeviceStatusChange = (dispatch, message) => {
  console.log("onDeviceStatusChange dispatch");
  console.log(message);
  dispatch(updateOrganizationDataSuccess({data: JSON.parse(message)}))
};

const TAB = {
  REALTIME_DATA: "realtimedata",
  CHART: "chart"
}

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.Login.user);
  const memoizedUser = useMemo(() => user, [user]);
  const logoPath = memoizedUser.user?.organizationLogo ?? null;
  const organizationName = memoizedUser.user?.organizationName ?? "";
  const dataSeq = useSelector(state => state.Data.seq);
  const dataZone = useSelector(state => state.Data.data);
  const [imageSrc, setImageSrc] = useState('');
  const [tab, setTab] = useState(TAB.REALTIME_DATA);

  useEffect(() => {
    const importImage = async () => {
      try {
        const imageModule = await import(`../../assets/images/companyLogo/${logoPath}`);
        setImageSrc(imageModule.default);
      } catch (error) {
        console.error('Error importing image:', error);
      }
    };
    if (logoPath !== null && logoPath !== undefined) {
      importImage();
    }
  }, [logoPath]);

  useEffect(() => {
    dispatch(getOrganizationDevice());
    dispatch(getOrganizationData());
    
    // start socket
    const dataConnector = DataConnector.getInstance();
    dataConnector.createSocket();
    dataConnector.setCBs(
      () => {onConnected(dispatch)}, 
      () => {onDisconnected(dispatch)}, 
      (data) => {onDeviceStatusChange(dispatch, data)});
    dataConnector.setup();
  }, []);

  return (
    <React.Fragment>
      <div id="wrapper">
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-brand dash-navbar-brand">
              {logoPath !== undefined && logoPath !== "" ?
              <img src={imageSrc} className="dash-logo" style={{paddingRight: 5}}/>:null}
              <h4 className="dash-header">{organizationName}</h4>
            </div>
            <div className="navbar-right dash-navbar-right">
              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  {tab === TAB.REALTIME_DATA?
                  <li>
                    <a className="icon-menu"><i className="icon-pie-chart" onClick={() => {
                      setTab(TAB.CHART);
                    }}></i></a>
                  </li>:
                  <li>
                  <a className="icon-menu"><i className="icon-home" onClick={() => {
                    setTab(TAB.REALTIME_DATA);
                  }}></i></a>
                  </li>}
                  <li>
                    <a className="icon-menu"><i className="icon-login" onClick={() => {
                      dispatch(logoutUser());
                    }}></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div id="main-content">
          <div className="container-fluid">
            {tab === TAB.REALTIME_DATA?
            <div className="row g-3">
              {
                dataSeq.map((zoneID, index) => {
                  let zone = dataZone[zoneID];
                  if (zone === undefined || zone === null) {
                    return null;
                  }
                  else {
                    return (
                    <div key={zoneID} className="col-lg-4 col-md-12">
                      {getZoneHeader(index, zone.zoneName, t)}
                      {
                        zone.devices.map(device => {
                          return (
                            <DeviceItem key={device.deviceId} device={device} sparklineColor={getSparlineColor(index)} switchToChart={()=>{
                              setTab(TAB.CHART);
                            }}/>
                          );
                        })
                      }
                    </div>
                    );
                  }
                })
              }              
            </div>
            :<ChartSection />}             
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
