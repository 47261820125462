import { APIClient } from '../../common/apiHelper';
import { GET_ORGANIZATION_DATA, GET_ORGANIZATION_DATA_CHART, GET_ORGANIZATION_DEVICE } from '../../common/urlHelper';
import { getOrganizationDataChartSuccess, getOrganizationDataSuccess, getOrganizationDeviceSuccess } from './reducer';

const api = new APIClient();

export const getOrganizationDevice = () => async (dispatch) => {
  var data = await api.get(GET_ORGANIZATION_DEVICE);
  if (data) {  
    dispatch(getOrganizationDeviceSuccess(data));
  }
};

export const getOrganizationData = () => async (dispatch) => {
  var data = await api.get(GET_ORGANIZATION_DATA);
  if (data) {  
    dispatch(getOrganizationDataSuccess(data));
  }
};

export const getOrganizationDataForChart = () => async (dispatch) => {
  var data = await api.get(GET_ORGANIZATION_DATA_CHART);
  if (data) {  
    dispatch(getOrganizationDataChartSuccess(data));
  }
};