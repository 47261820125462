import { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DEVICE_TYPE } from '../../common/constant';

function createAxisAndSeries(t, root, chart, deviceType, opposite, xAxis, data, tooltipFun = (v) => {return v;}) {
    var yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite
    });
    var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 1,
            renderer: yRenderer,
            visible: deviceType !== DEVICE_TYPE.WATER_LEVEL
        })
    );

    if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
    }

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: t('Common.DeviceType.' + deviceType),
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}"
            })
        })
    );

    series.get("tooltip").label.adapters.add("text", function(text, target) {
        text = "";
        var tooltipDataItem = series?.get("tooltipDataItem");
        text += series?.get("name") + " : " + tooltipFun(tooltipDataItem?.get("valueY")); // Edit the text as per requirement.
        return text
      });

    //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
    series.strokes.template.setAll({ strokeWidth: 1 });

    // Show bullets
    series.bullets.push(function(root) {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: series.get("fill")
          })
        });
      });

    yRenderer.grid.template.set("strokeOpacity", 0.05);
    yRenderer.labels.template.set("fill", series.get("fill"));
    yRenderer.setAll({
        stroke: series.get("fill"),
        strokeOpacity: 1,
        opacity: 1
    });

    // Set up data processor to parse string dates
    // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
    series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd hh:mm",
        dateFields: ["date"]
    });

    series.data.setAll(processValue(data, deviceType));
}

export function processValue(value, deviceType) {
    var data = [];
    if (value === undefined || value === null) {
        return data;
    }
    for (var i = 0; i < value.length; i++) {
        var v = value[i];
        if (deviceType === DEVICE_TYPE.WATER_LEVEL) {
            data.push({
                date: new Date(parseFloat(v["date"])),
                value: v['value'] === "high"? 100: 0
            })
        }
        else {
            data.push({
                date: new Date(parseFloat(v["date"])),
                value: parseFloat(v['value'])
            })
        }
    }

    return data;
}
  

function Chart(props) {
    const { t } = useTranslation();
    const data = useSelector(state => state.Data.chart?.[props.zoneID]);
    const devices = useSelector(state => state.Data.data?.[props.zoneID]?.devices);

    useLayoutEffect(() => {
        let root = am5.Root.new(props.name);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                focusable: true,
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: root.verticalLayout
            })
        );
        chart.get("colors").set("step", 3);

        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
              maxDeviation: 0.1,
              groupData: false,
              baseInterval: {
                timeUnit: "minute",
                count: 30
              },
              renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 80,
                minorGridEnabled: true
              }),

              tooltip: am5.Tooltip.new(root, {})
            })
        );

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        // add scrollbar
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

        if (devices !== undefined && data !== undefined) {
            devices?.map((device, index) => {
                let isOpposite = devices?.length / 2 <= index;
                console.log("deviceID: " + device["deviceId"]);
                if (device.deviceType === DEVICE_TYPE.WATER_LEVEL) {
                    createAxisAndSeries(t, root, chart, device.deviceType, isOpposite, xAxis, data[device["deviceId"]], (value)=>{
                        if (value === 100) {
                            return "high";
                        }
                        return "low";
                    });
                }
                else {
                    createAxisAndSeries(t, root, chart, device.deviceType, isOpposite, xAxis, data[device["deviceId"]]);
                }
            })
        }
        
        let legend = chart.children.push(am5.Legend.new(root, {
            nameField: "categoryX",
            centerX: am5.percent(50),
            x: am5.percent(50)
        }));
        legend.data.setAll(chart.series.values);

        return () => {
            root.dispose();
        };
    }, [data, devices]);

    return (
        <div id={props.name} style={{ width: "100%", height: "500px" }}></div>
    );
}
export default Chart;