import React, { useMemo } from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthProtected = (props) => {
  const user = useSelector(state => state.Login.user);
  const memoizedUser = useMemo(() => user, [user]); 

  if (memoizedUser === null || memoizedUser === undefined || memoizedUser === "") {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };