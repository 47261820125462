import { useEffect } from "react";
import { getOrganizationDataForChart } from "../../slices/data/thunk";
import { useDispatch, useSelector } from "react-redux";
import { getZoneHeader } from ".";
import Chart from "./Chart";
import { useTranslation } from "react-i18next";

function ChartSection() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataSeq = useSelector(state => state.Data.seq);
    const dataZone = useSelector(state => state.Data.data);

    useEffect(()=> {
        dispatch(getOrganizationDataForChart());
    }, [])

    return (
       <div className="row g-3">
            {
            dataSeq?.map((zoneID, index) => {
                let zone = dataZone[zoneID];
                if (zone === undefined || zone === null) {
                return null;
                }
                else {
                return (
                <div key={zoneID} className="col-lg-6 col-md-12">
                    {getZoneHeader(index, zone.zoneName, t)}
                    <Chart key={zoneID} name={"chartdiv"+zone.zoneId} zoneID={zoneID}/>
                </div>
                );
                }
            })
            }    
        </div>         
    );
}

export default ChartSection;