import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
  seq: [],
  device: {
  },
  chart: {}
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    getOrganizationDeviceSuccess(state, action) {
      return {
        ...state,
        ...action.payload.data,
      }
    },
    getOrganizationDataSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        device: {
          ...state.device,
          ...action.payload.data,
        }
      }
    },
    getOrganizationDataChartSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        chart: {
          ...action.payload.data,
        }
      }
    },
    updateOrganizationDataSuccess(state, action) {
      console.log(action);
      let newDeviceData = {};
      for (let key in action.payload.data) {
        if (action.payload.data.hasOwnProperty(key)) { // Ensure the property is a direct property of the object, not inherited
          let newData = action.payload.data[key];     
          let data = state.device[key];
          let newDataList = data !== undefined? [...data]: [];
          if (newDataList.length > 0) {
            if (newDataList[newDataList.length - 1]["date"] < newData["date"]) {
              if (data.length >= 10) {
                newDataList.shift();
              }
              newDataList.push(newData);
              newDeviceData[key] = newDataList;
            }
          }          
        }
      }
      return {
        ...state,
        device: {
          ...state.device,
          ...newDeviceData,
        }
      }
    },
    resetState: (state) => {return {...initialState}},
  },
});

export const {
  getOrganizationDeviceSuccess,
  getOrganizationDataSuccess,
  getOrganizationDataChartSuccess,
  updateOrganizationDataSuccess,
  resetState,
} = dataSlice.actions

export default dataSlice.reducer;