import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: "",
  error: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      return {
        ...state,
        user: action.payload.data
      }
    },
    logoutUserSuccess(state, action) {
      return {
        ...initialState
      }
    },
    initializeFromSessionSuccess(state, action) {
      return {
        ...state,
        user: action.payload
      }
    },
    resetState: (state) => {return {...initialState}},
  },
});

export const {
  loginSuccess,
  logoutUserSuccess,
  resetState,
  initializeFromSessionSuccess,
} = loginSlice.actions

export default loginSlice.reducer;