module.exports = {
    DEVICE_TYPE: {
        LUMINANCE: "luminance",
        PH: "ph",
        EC: "ec",
        WATERFLOW: "waterflow",
        WATER_LEVEL: "water_level",
        WATER_TEMP: "water_temperature",
        INDOOR_TEMPERATURE: "indoor_temperature",
        INDOOR_HUMIDITY: "indoor_humidity",
        INDOOR_CO2: "indoor_CO2"
    },
    DEVICE_UNIT: {
        degreeC: "degreeC",
        percentage: "percentage"
    }
}