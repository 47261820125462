const isDev = process.env.NODE_ENV === "development";
const isStage = process.env.REACT_APP_STAGE === "stage";

module.exports = {
  api: {
    API_URL: isDev || isStage ? "http://192.168.0.17:3005": "https://api.hydro.bnet-tech.com",
  },
  socket: {
    SOCKET_URL: isDev || isStage ? "ws://192.168.0.17:3005": "wss://api.hydro.bnet-tech.com",
    SOCKET_PATH:  "/socket.io/",
  }
};