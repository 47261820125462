import React, { useState } from 'react';

//redux
import { useDispatch } from "react-redux";

//import { Link } from "react-router-dom";
import withRouter from "../../components/common/withRouter";

import { useTranslation } from 'react-i18next';

import logoWhite from '../../assets/images/logo-white.svg';
import { loginUser } from '../../slices/thunks';

const Login = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    return (
        <React.Fragment>
            <div id="wrapper">
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main">
                        <div className="auth-box">
                            <div className="top">
                                <img src={logoWhite} alt="BnetHydro" />
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">{t('PageLogin.Title')}</p>
                                </div>
                                <div className="body">
                                    <form className="form-auth-small" action="index.html">
                                        <div className="form-group mb-2">
                                            <label htmlFor="signin-email" className="control-label sr-only">{t('PageLogin.Username')}</label>
                                            <input type="text" className="form-control" id="signin-email" value={username} onChange={(e) => {setUsername(e.target.value); setError("");}} placeholder={t('PageLogin.Username')} autoComplete="username"/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="signin-password" className="control-label sr-only">{t('PageLogin.Password')}</label>
                                            <input type="password" className="form-control" id="signin-password" value={password} onChange={(e) => {setPassword(e.target.value); setError("");}}  placeholder={t('PageLogin.Password')} autoComplete="current-password"/>
                                        </div>
                                        <div className='error-msg login-error-msg'>{error}</div>
                                        <button type="button" className="btn btn-primary btn-lg btn-block" onClick={()=>{
                                            if (username === "") {
                                                setError(t('PageLogin.ErrorMissing') + t('Common.Space') + t('PageLogin.Username'));
                                            }
                                            else if (password === "") {
                                                setError(t('PageLogin.ErrorMissing') + t('Common.Space') + t('PageLogin.Password'));
                                            }
                                            else {
                                                dispatch(loginUser({username: username, password: password})).then(() => {
                                                    // Dispatch succeeded, additional logic here
                                                    props.router.navigate('dashboard');
                                                })
                                                .catch((error) => {
                                                    // Handle errors if dispatch fails
                                                    setError(t('PageLogin.ErrorWrongLogin'));
                                                    console.error('Login failed:', error);
                                                });
                                            }
                                        }}>{t('PageLogin.BtnLogin')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);