import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/dashboard";

//login
import Login from "../pages/auth/Login";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "*", component: <Dashboard /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
