import { combineReducers } from "redux";

import LoginReducer from "./auth/reducer";
import DataReducer from "./data/reducer";


const rootReducer = combineReducers({
    Login: LoginReducer,
    Data: DataReducer
});

export default rootReducer;