import { loginSuccess, logoutUserSuccess, initializeFromSessionSuccess } from './reducer';
import { POST_LOGIN } from '../../common/urlHelper';
import { APIClient, setAuthorization } from '../../common/apiHelper';

const api = new APIClient();

export const loginUser = (user) => async (dispatch) => {
  var data = await api.create(POST_LOGIN, {username: user.username, pwd: user.password});
  if (data) {
    sessionStorage.setItem("authUser", JSON.stringify(data.data));   
    setAuthorization(data.data.credential.accessToken); 
    dispatch(loginSuccess(data));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));

  } catch (error) {
    console.log(error)
  }
};

export const initializeFromSession = (user) => async (dispatch) =>{
  try {
    const response = dispatch(initializeFromSessionSuccess(user));
    return response;
  } catch (error) {
    console.log(error)
  }
};