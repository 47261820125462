import React, { Fragment, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { DEVICE_TYPE, DEVICE_UNIT } from "../../common/constant";

import { faWater, faFlask, faBolt, faTemperatureHalf, faGlassWhiskey, faDroplet, faCloud, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";

import { processValue } from "./Chart";

const getIcon = (deviceType) => {
  if (deviceType === DEVICE_TYPE.LUMINANCE) {
    return faLightbulb;
  }
  else if (deviceType === DEVICE_TYPE.WATERFLOW) {
    return faWater;
  }
  else if (deviceType === DEVICE_TYPE.WATER_LEVEL) {
    return faGlassWhiskey;
  }
  else if (deviceType === DEVICE_TYPE.PH) {
    return faFlask;
  }
  else if (deviceType === DEVICE_TYPE.EC) {
    return faBolt;
  }
  else if (deviceType === DEVICE_TYPE.WATER_TEMP || deviceType === DEVICE_TYPE.INDOOR_TEMPERATURE) {
    return faTemperatureHalf;
  }
  else if (deviceType === DEVICE_TYPE.INDOOR_HUMIDITY) {
    return faDroplet;
  }
  else if (deviceType === DEVICE_TYPE.INDOOR_CO2) {
    return faCloud;
  }
}

const getUnit = (unit) => {
  if (unit === DEVICE_UNIT.degreeC) {
    return "°C";
  }
  else if (unit === DEVICE_UNIT.percentage) {
    return "%";
  }
  return unit;
}

function DeviceItem(props) {
  const { t } = useTranslation();
  const device = props.device;
  let data = useSelector(state => state.Data.device?.[device.deviceId]);
  const divName = "D_" + device.deviceId;
  const { sparklineColor, switchToChart } = props;
  const chartData = processValue(data);

  useLayoutEffect(() => {
    if (data !== undefined && chartData.length > 0 && device.deviceType !== DEVICE_TYPE.WATER_LEVEL) {
    var root = am5.Root.new(divName);

    root.setThemes([
      am5themes_Micro.new(root)
    ]);

    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none"
    }));
    
    chart.plotContainer.set("wheelable", false);
    chart.zoomOutButton.set("forceHidden", true);

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "minute",
          count: 30
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 80,
          minorGridEnabled: true
        })
      })
    );

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis,
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
        visible: false,
        scale: 1,
        strictMinMax: true,
      })
    );

    var series = chart.series.push(am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "date",
      stroke: sparklineColor,  
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "vertical",
        labelText: "{valueY}"
      })
    }));

    series.get("tooltip").label.adapters.add("text", function (text, target) {
      text = "";
      var tooltipDataItem = series?.get("tooltipDataItem");
      text += tooltipDataItem?.get("valueY"); // Edit the text as per requirement.
      return text
    });

    series.strokes.template.setAll({
      strokeWidth: 3
    });

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd hh:mm",
      dateFields: ["date"]
    });


    series.data.setAll(chartData);

    return () => {
      root.dispose();
    };
  }

  }, [chartData]);

  return (
    <div className="card dash-zone-item-card">
      <div className="body">
        <div className="row dash-zone-item">
          <div className="col-12 dash-zone-item-header-container">
            <div className="dash-zone-item-header">{t('Common.DeviceType.' + device.deviceType)}</div>
          </div>
          <div className="col-12 dash-zone-item-container">
            <div className="col-4 dash-zone-item-icon"><FontAwesomeIcon icon={getIcon(device.deviceType)} /></div>
            <div className="col-8 dash-zone-item-text-container">
              {data === undefined || data.length === 0 ? "-" :
                <Fragment>
                  <div className="dash-zone-item-text">{data[data.length - 1].value}</div>
                  <div className="dash-zone-item-unit">{getUnit(device.deviceTypeValueUnit)}</div>
                  {device.deviceType !== DEVICE_TYPE.WATER_LEVEL?
                  <div onClick={()=>{switchToChart();}} id={divName} style={{ width: "100%", height: "40px", marginLeft: '2rem', maxWidth: "200px" }}></div>
                  :null}
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceItem;