import './App.css';
import Route from './routes';
import React, { useEffect } from "react";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import rootReducer from "./slices";
import { configureStore } from "@reduxjs/toolkit";
import './assets/scss/themes.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n'; // Import the i18next instance
import { initializeFromSession } from './slices/thunks';
import { getLoggedinUser } from './common/apiHelper';

const persistedUser = getLoggedinUser();
const store = configureStore({ 
  reducer: rootReducer, 
  preloadedState: {
    Login: {
      user: persistedUser
    },
  },
  devTools: true 
});

function App() {
  useEffect(() => {
    // Check session storage for authenticated user data
    const user = sessionStorage.getItem('authUser') !== undefined?JSON.parse(sessionStorage.getItem('authUser')):null;
    if (user) {
      store.dispatch(initializeFromSession(user));
    }
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Route />
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
